import React from 'react';

class Login extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    window.location.replace('https://app.cpeu.org/login');
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <>
        Redirecting to login. If you are not automatically directed,{' '}
        <a href={'https://app.cpeu.org/login'}>click here.</a>{' '}
      </>
    );
  }
}

export default Login;
